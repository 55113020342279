import React from 'react';
import style from './contactdata.module.css';

interface Props {
    title: string;
    value: string | undefined;
}

const ContractData: React.FC<Props> = ({ title, value }) => {

    return (
        <div className={style.wrapper}>
            <h2>{title}</h2>
            <p>{value ?? "N/A"}</p>
        </div>
    )
}

export default ContractData;