import React, { useState, useEffect, useRef, useCallback } from 'react';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import style from './main.module.css';
import ContractData from '../ContractData';
import { format } from 'date-fns';

interface IPartialContact {
    ID: string;
    "thames-customer-contract-details": string; // json
}

interface IThamesAccount {
    contractAccountNumber: string;
    paymentScheme: {
        status: "Deactivated" | "Activated";
        startDate: Date
        endDate: Date
    }
    meterBilLGroup: string;
}

interface IThamesPerson {
    firstName: string;
    lastName: string;
}

const formatDate = (start: Date | undefined, end: Date | undefined) => {
    if (!start || !end) return;
    const dformat =  'dd/MM/yyyy'
    const startf = format(new Date(start), dformat);
    const endf = format(new Date(end), dformat);
    return `${startf} - ${endf}`
}

const formatName = (first: string | undefined, last: string | undefined) => {
    if (!first || !last) return;
    return `${first} ${last}`
}

const Main: React.FC<any> = props => {
    const smartagent = useRef(new SmartAgent()).current;
    const [account, setAccount] = useState<IThamesAccount | undefined>(undefined);
    const [person, setPerson] = useState<IThamesPerson | undefined>(undefined);

    const setup = useCallback(async () => {
        smartagent.init();

        smartagent.on('contact_updated', (contact: IPartialContact) => {
            try {
                if (!contact || !contact['thames-customer-contract-details']) {
                    setPerson(undefined);
                    setAccount(undefined);
                } else {
                    const { account, personDetails } = JSON.parse(
                        contact['thames-customer-contract-details']
                    );
                    setPerson(personDetails);
                    setAccount(account);
                }
            } catch (error) {
                setPerson(undefined);
                setAccount(undefined);
            }
        })
    }, [smartagent])

    useEffect(() => {
        setup();
    }, [setup]);

    console.log('thames');
    

    return (
        <div className={style.wrapper}>
            <div className={style.row}>
                <ContractData
                    title="Account Number"
                    value={account?.contractAccountNumber}
                />
                <ContractData
                    title="Payment Scheme Status"
                    value={account?.paymentScheme?.status}
                />
                <ContractData
                    title="Meter bill Group"
                    value={account?.meterBilLGroup}
                />
                <ContractData
                    title="Installation Point"
                    value={undefined} //?
                />
            </div>
            <div className={style.row}>
                <ContractData
                    title="Contract Date"
                    value={formatDate(account?.paymentScheme?.startDate, account?.paymentScheme?.endDate)}
                />
                <ContractData
                    title="Business Partner Name"
                    value={formatName(person?.firstName, person?.lastName)}
                />
            </div>
        </div>
    )
};

export default Main;
